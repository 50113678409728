<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :button1="btn" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>

            <div class="auth-page-right-content">
              <div class="heading">
                <ThemeButton />
                <h6>Forgot Password</h6>
                <p
                  v-if="errorMessage != null"
                  class="error"
                 
                >
                  {{ errorMessage }}
                </p>
                <p v-else>
                  Please provide your business email to reset your password
                </p>
              </div>
              <form @submit.prevent @keyup.enter="forgot(0)">
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../../../assets/images/email-icon.png" alt="" />
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Business Email"
                    autocomplete="off"
                    v-model.trim="$v.details.email.$model"
                    :class="{
                      'is-invalid': $v.details.email.$error,
                      'is-valid': !$v.details.email.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.email.required"
                      >Business email is required</span
                    >
                    <span v-if="!$v.details.email.email"
                      >Business email must be valid
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  class="auth-btn"
                  @click="forgot(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Next</span>
                </button>
                <div class="account d-md-none">
                <router-link to="/register" class="forgot-password"
                  >Register</router-link
                >
              </div>
              </form>
              <div class="account">
                <router-link to="/login"  class="forgot-password"
                  >Login</router-link
                >
              </div>
              <!-- <span class="account">
                Remember Password ?
                <router-link to="/login">Login</router-link>
              </span> -->
            </div>
            <div class="circle-one d-block d-md-none">
              <img src="../../../../assets/images/circle1.png" />
            </div>
            <div class="circle-two d-block d-md-none">
              <img src="../../../../assets/images/circle2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeButton from "../../../../components/Enterprise/Theme/ThemeButton.vue";
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import AuthLeft from "../../../../components/Enterprise/authleft/AuthLeft.vue";

export default {
  name: "EnterpriseForgotPassword",

  components: {
    ThemeButton,
    AuthLeft,
  },

  data: function() {
    return {
      errorMessage: null,
      isLoadingArray: [],
      details: {
        email: "",
      },
      btn: "Register",
      para: "Simplify your NDA. But first, let’s reset your password.",
    };
  },
  validations: {
    details: {
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendResetPasswordRequest"]),

    forgot: function(index) {
      
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.sendResetPasswordRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "EnterpriseVerifyEmail"});
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },
  },
};
</script>
