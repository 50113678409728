var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-page"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6 d-none d-md-block"},[_c('AuthLeft',{attrs:{"paragraph":_vm.para,"button1":_vm.btn}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"auth-page-right"},[_vm._m(0),_c('div',{staticClass:"auth-page-right-content"},[_c('div',{staticClass:"heading"},[_c('ThemeButton'),_c('h6',[_vm._v("Forgot Password")]),(_vm.errorMessage != null)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_c('p',[_vm._v(" Please provide your business email to reset your password ")])],1),_c('form',{on:{"submit":function($event){$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.forgot(0)}}},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.email.$error,
                    'is-valid': !_vm.$v.details.email.$invalid,
                  },attrs:{"type":"email","id":"email","placeholder":"Business Email","autocomplete":"off"},domProps:{"value":(_vm.$v.details.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.email.required)?_c('span',[_vm._v("Business email is required")]):_vm._e(),(!_vm.$v.details.email.email)?_c('span',[_vm._v("Business email must be valid ")]):_vm._e()])]),_c('button',{staticClass:"auth-btn",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.forgot(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Next")])]),_c('div',{staticClass:"account d-md-none"},[_c('router-link',{staticClass:"forgot-password",attrs:{"to":"/register"}},[_vm._v("Register")])],1)]),_c('div',{staticClass:"account"},[_c('router-link',{staticClass:"forgot-password",attrs:{"to":"/login"}},[_vm._v("Login")])],1)]),_vm._m(2),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('h4',[_vm._v("Global NDA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-icon"},[_c('img',{attrs:{"src":require("../../../../assets/images/email-icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle-one d-block d-md-none"},[_c('img',{attrs:{"src":require("../../../../assets/images/circle1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle-two d-block d-md-none"},[_c('img',{attrs:{"src":require("../../../../assets/images/circle2.png")}})])
}]

export { render, staticRenderFns }